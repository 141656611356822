/* App.css */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.container {
  width: 100%;
  margin: 0 auto;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 5px;
  }
}