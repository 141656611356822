@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');

.meet-happy-clients {
  font-family: 'Roboto Slab', serif;
  text-align: center;
  padding: 2rem 2rem;
  background-image: url('../../images/happyclient.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
}

.meet-happy-clients::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
}

.client-title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 1;
  font-weight: 300;
  color: #333;
}

.client-title::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #FFB901;
  margin: 10px auto 0;
}

.section-subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
  font-weight: 300;
  color: #666;
}

.highlight {
  font-weight: 700;
  color: #333;
}

.testimonial-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 60px;
}


.carousel-button {
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  outline: none; /* Remove focus outline */
}

.carousel-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

/* .carousel-button:active {
  transform: translateY(-50%); 
} */

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.testimonial {
  max-width: 600px;
  margin: 0 auto;
}

.testimonial-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  font-weight: 300;
  color: #333;
}

.author-name {
  font-weight: 600;
  margin-bottom: 0.2rem;
  color: #868690;
  font-style: italic;
}

.author-position {
  font-style: italic;
  color: #868690;
  font-weight: 300;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.star-icon {
  color: #ffd700;
  margin: 0 2px;
  transition: all 0.3s ease;
  font-size: larger;
}

.star-icon:hover {
  color: #ffaa00;
  filter: drop-shadow(0 0 5px rgba(255, 215, 0, 0.7));
}

@keyframes twinkle {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
}

.avatar:hover .star-icon {
  animation: twinkle 1s infinite;
}

.avatar:hover .star-icon:nth-child(1) { animation-delay: 0s; }
.avatar:hover .star-icon:nth-child(2) { animation-delay: 0.1s; }
.avatar:hover .star-icon:nth-child(3) { animation-delay: 0.2s; }
.avatar:hover .star-icon:nth-child(4) { animation-delay: 0.3s; }
.avatar:hover .star-icon:nth-child(5) { animation-delay: 0.4s; }

@media (max-width: 768px) {
  .client-title {
    font-size: 2rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }

  .testimonial-text {
    font-size: 1rem;
  }

  .carousel-button {
    display: none;
  }
}

@media (max-width: 480px) {
  .client-title {
    font-size: 1.8rem;
  }

  .testimonial {
    margin: 0 10px;
  }
}