/* Main container styles */
.service-quote {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    font-family: 'Roboto Slab', serif;
    background-color: #ffffff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Header styles */
.service-quote h2 {
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 1em;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    padding: 15px 20px;
    overflow: hidden;
    background: linear-gradient(135deg, #00c271, #2e4e7d);
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(46, 57, 125, 0.3);
    transition: all 0.3s ease;
}

.service-quote h2:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(46, 57, 125, 0.4);
}

/* Highlight effect */
.service-quote h2::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    transform: rotate(30deg);
    transition: transform 0.5s ease;
}

.service-quote h2:hover::before {
    transform: rotate(0deg);
}

/* Text shadow for better readability */
.service-quote h2 span {
    position: relative;
    z-index: 1;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

/* Animated underline */
.service-quote h2::after {
    content: '';
    position: absolute;
    bottom: 8px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s ease;
}

.service-quote h2:hover::after {
    width: 60%;
    left: 20%;
}

/* Form layout */
.service-quote form {
    display: grid;
    gap: 20px;
}

/* Form section headings and labels */
.subservices h3, .number-of-photos label, .dropbox-link label, .instructions label {
    font-size: 0.9em;
    color: #444;
    margin-bottom: 5px;
    font-weight: 500;
}

/* Subservice item styles */
.subservices {
    margin-bottom: 10px;
}

.subservices h3 {
    font-size: 1em;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
}



.number-of-photos{
    position: relative;
    top:-10px;
}
.dropbox-link {
    position: relative;
    top:-23px;
}
.instructions {
    position: relative;
    top: -35px;
}
.total-price{
    position: relative;
    top:-30px;
}
.tp-button {
    position: relative;
    top:-35px;
}


.subservices-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
}

.subservice-item {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 12px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
}

.subservice-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
}

.subservice-item label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.8em;
    color: #444;
}

.subservice-item input[type="checkbox"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    border: 2px solid #4CAF50;
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.3s ease;
    position: relative;
    cursor: pointer;
}

.subservice-item input[type="checkbox"]:checked {
    background-color: #4CAF50;
}

.subservice-item input[type="checkbox"]:checked::after {
    content: '\2714';
    font-size: 12px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.subservice-item .subservice-details {
    display: flex;
    flex-direction: column;
}

.subservice-item .subservice-name {
    font-weight: 500;
    margin-bottom: 5px;
}

.subservice-item .subservice-price {
    font-size: 0.8em;
    color: #666;
}

/* Form section styles */
.number-of-photos, .dropbox-link, .instructions, .total-price {
    margin-bottom: 0px;
}

/* Label styles */
label {
    display: block;
    margin-bottom: 0px;
    font-weight: 500;
    color: #333;
}

/* Input, select, and textarea styles */
input[type="number"], input[type="text"], select, textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    font-size: 0.9em;
    transition: all 0.3s ease;
}

input[type="number"]:focus, input[type="text"]:focus, select:focus, textarea:focus {
    outline: none;
    /* border-color: #4CAF50; */
    box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.2);
}

/* Custom styles for number input */
input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Select styles */
select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    padding-right: 40px;
}

/* Textarea styles */
textarea {
    height: 100px;
    resize: vertical;
}

/* Button styles */
button {
    background-color: #FFB900;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 500;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button:hover {
    background-color: #FFB900;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Total price display */
.total-price {
    text-align: right;
    font-size: 1em;
    color: #333;
    font-weight: 600;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Dropbox link styles */
.dropbox-link p {
    margin-top: 15px;
    word-break: break-all;
    font-size: 0.8em;
    color: #666;
    background-color: #f9f9f9;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
}

.dropbox-link a {
    color: #4CAF50;
    text-decoration: none;
    transition: color 0.3s ease;
    font-weight: 500;
}

.dropbox-link a:hover {
    color: #45a049;
    text-decoration: underline;
}

/* Service links styles */
.service-links {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.service-links a {
    text-decoration: none;
    color: var(--text-color);
    transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
    border: 2px solid var(--primary-color);
    border-radius: 25px;
    padding: 6px 16px;
    font-weight: 500;
    font-size: 0.9em;
}

.service-links a:hover {
    color: var(--bg-color);
    background-color: var(--primary-color);
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(255, 185, 0, 0.3);
}

/* Additional style for Quote link */
.service-links .quote-link {
    background-color: var(--primary-color);
    color: var(--bg-color);
}

.service-links .quote-link:hover {
    background-color: #e6a800;
    border-color: #e6a800;
}

/* Responsive design */
@media (max-width: 600px) {
    .service-quote {
        padding: 15px;
    }

    .service-quote h2 {
        font-size: 1.8em;
        padding: 12px 15px;
    }

    .subservices-grid {
        grid-template-columns: 1fr;
    }

    input[type="number"], input[type="text"], select, textarea, button {
        font-size: 16px;
    }
    
    .total-price {
        font-size: 1.1em;
        padding: 12px;
    }

    .service-links {
        flex-direction: column;
    }

    .service-links a {
        width: 100%;
        text-align: center;
    }
}