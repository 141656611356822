@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSmYmRjRdE.woff2) format('woff2');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmYmRjRdE.woff2) format('woff2');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjovoSmYmRjRdE.woff2) format('woff2');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OmYmRjRdE.woff2) format('woff2');
}

.op-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #FFB901;
  font-family: 'Roboto Slab', serif;
}

.op-title {
  text-align: center;
  color: #333;
  font-weight: 500;
}

.op-description {
  text-align: center;
  color: black;
  margin-bottom: 40px;
  font-weight: 300;
}

.op-steps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.op-step {
  flex-basis: calc(33.333% - 20px);
  margin-bottom: 30px;
  text-align: center;
}

.op-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #4CAF50;
}

.op-step h3 {
  margin-bottom: 10px;
  color: #333;
  font-weight: 500;
}

.op-step p {
  color: black;
  font-weight: 300;
}

.op-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.op-button {
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Roboto Slab', serif;
  font-weight: 500;
}

.op-button-primary {
  background-color: #4CAF50;
  color: white;
}

.op-button-primary:hover {
  background-color: #45a049;
}

.op-button-secondary {
  background-color: white;
  color: #4CAF50;
  border: 2px solid #4CAF50;
}

.op-button-secondary:hover {
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .op-step {
    flex-basis: 100%;
  }
  
  .op-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .op-button {
    width: 100%;
    max-width: 250px;
    margin-bottom: 10px;
  }
}