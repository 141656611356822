@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.en-container {
  display: flex;
  max-width: 1200px;
  margin: 40px auto;
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.en-image-section {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.en-photographer {
  max-width: 100%;
  height: 70%;
  border-radius: 10px;
}

.en-form-section {
  flex: 1;
  padding: 40px;
  background-color: #f8f9fa;
}

.en-title {
  font-size: 32px;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.en-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.en-input, .en-textarea {
  width: 100%;
  padding: 12px 15px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
  background-color: #ffffff;
}

.en-input:focus, .en-textarea:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  outline: none;
}

.en-row {
  display: flex;
  gap: 20px;
}

.en-textarea {
  height: 120px;
  resize: vertical;
}

.en-file-upload {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.en-file-input {
  padding: 12px;
  border: 2px dashed #3498db;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #ecf0f1;
}

.en-file-input:hover {
  background-color: #d6eaf8;
}

.en-file-info {
  font-size: 12px;
  color: #7f8c8d;
  font-style: italic;
}

.en-captcha-row {
  display: flex;
  gap: 20px;
  align-items: center;
}

.en-captcha-input {
  flex: 1;
}

.en-captcha-image {
  padding: 12px 20px;
  background-color: #34495e;
  color: #ffffff;
  border-radius: 8px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.en-captcha-image:hover {
  background-color: #2c3e50;
}

.en-consent-row {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.en-consent-label {
  font-size: 14px;
  line-height: 1.5;
  color: #34495e;
}

.en-submit-btn {
  background-color: #3498db;
  color: white;
  padding: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.en-submit-btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.en-privacy-policy {
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #3498db;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.en-privacy-policy:hover {
  color: #2980b9;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .en-container {
    flex-direction: column;
    margin: 20px;
  }

  .en-row {
    flex-direction: column;
  }

  .en-title {
    font-size: 28px;
  }
}