/* Navbar.css */

:root {
  --primary-color: #FFB900;
  --secondary-color: #FF4E41;
  --text-color: #333;
  --bg-color: #fff;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --transition-speed: 0.3s;
  --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Main Navbar Container Styles */
.navbar {
  position: sticky;
  top: 0;
  background-color: var(--bg-color);
  box-shadow: 0 4px 20px var(--shadow-color);
  z-index: 1001;
  overflow: visible;
  width: 100%;
  transition: all var(--transition-speed) ease;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px 24px;
}

.navbar-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.navbar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

/* Logo Styles */
.navbar-logo img {
  height: 5rem;
  display: block;
  transition: transform var(--transition-speed) ease;
  filter: drop-shadow(0 2px 4px var(--shadow-color));
}

.navbar-logo img:hover {
  transform: scale(1.05);
}

/* Hamburger menu */
.hamburger-menu {
  display: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1002;
}

/* Menu Styles */
.navbar-menu {
  display: flex;
  gap: 32px;
  align-items: center;
  position: relative;
  left: 1rem;
}

.navbar-menu a {
  font-size: 18px;
  text-decoration: none;
  color: var(--text-color);
  font-weight: 600;
  transition: color var(--transition-speed), transform var(--transition-speed);
  position: relative;
  padding-bottom: 5px;
  font-family: var(--font-family);
}

.navbar-menu a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: width var(--transition-speed) cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.navbar-menu a:hover::before,
.navbar-menu a.active::before {
  width: 100%;
}

.navbar-menu a:hover,
.navbar-menu a.active {
  color: var(--primary-color);
  transform: translateY(-2px);
}

.navbar-menu a.has-dropdown::after {
  content: '▼';
  font-size: 10px;
  margin-left: 5px;
  vertical-align: middle;
  transition: transform var(--transition-speed);
}
.has-dropdown {
  position: relative;
  bottom:2.5px;
}

.navbar-menu a.has-dropdown:hover::after {
  transform: rotate(180deg);
}

/* Services Dropdown Styles */
.services-dropdown-container {
  position: relative;
    margin: 0;
  
    padding: 0;
}

.services-dropdown {
  position: absolute;

  top: 100%;
  left: 0;
  background-color: var(--bg-color);
  margin: 0;
  padding: 0;
  /* box-shadow: 0 4px 20px var(--shadow-color); */
  border-radius: 8px;
  /* padding: 16px; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* gap: 16px; */
  z-index: 1001;
  width: max-content;
  max-width: 800px;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform var(--transition-speed) ease;
}

.service-item:hover {
  transform: translateY(-4px);
}

.service-item img {
 
  object-fit: cover;
  border-radius: 4px;
  /* margin-bottom: 4px; */
}

.service-item img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  
}

.service-item span {
  font-size: 14px;
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

/* Common Link Styles */
.navbar-menu a, .navbar-links a {
  font-size: 16px;
  text-decoration: none;
  color: var(--text-color);
  font-family: var(--font-family);
}

/* Right Section Styles */
.navbar-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Top Links Styles */
.navbar-links {
  display: flex;
  gap: 16px;
  align-items: center;
}

.navbar-links a {
  text-decoration: none;
  color: var(--text-color);
  transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.navbar-links a:hover {
  color: var(--primary-color);
  transform: translateY(-2px);
}

/* Styles for Career and FAQ */
.navbar-links a[href="/career"],
.navbar-links a[href="/faq"] {
  font-weight: 400;
}

/* Add separator between Career and FAQ */
.navbar-links a[href="/career"] {
  position: relative;
  padding-right: 16px;
}

.navbar-links a[href="/career"]::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 1px;
  background-color: #ddd;
}

/* Styles for Login and Sign Up */
.navbar-links a[href="/login"],
.navbar-links a[href="/signup"] {
  border: 2px solid var(--primary-color);
  border-radius: 25px;
  padding: 8px 20px;
  font-weight: 500;
  transition: all var(--transition-speed) cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.navbar-links a[href="/login"]:hover,
.navbar-links a[href="/signup"]:hover {
  background-color: var(--primary-color);
  color: var(--bg-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 185, 0, 0.3);
}

/* Additional style for Sign Up to make it stand out */
.navbar-links a[href="/signup"] {
  background-color: var(--primary-color);
  color: var(--bg-color);
}

.navbar-links a[href="/signup"]:hover {
  background-color: #e6a800;
  border-color: #e6a800;
}

/* Button Container Styles */
.navbar-buttons {
  display: flex;
  gap: 12px;
  position: relative;
  z-index: 1001;
  margin-bottom: -2.8rem;
}

/* Button Styles */
.btn {
  background-color: var(--secondary-color);
  color: var(--bg-color);
  border: none;
  height: 4rem; /* Reduced from 5rem */
  font-family: var(--font-family);
  font-size: 9px; /* Reduced from 10px */
  font-weight: bold;
  text-transform: uppercase;
  padding: 0px 6px; /* Reduced from 0px 8px */
  cursor: pointer;
  transition: all var(--transition-speed) cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-bottom-left-radius: 64px 20px; /* Reduced from 80px 24px */
  border-bottom-right-radius: 64px 20px; /* Reduced from 80px 24px */
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px; /* Reduced from 70px */
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  line-height: 1.2; /* Slightly reduced from 1.3 */
  box-shadow: 0 3px 8px rgba(255, 78, 65, 0.3); /* Reduced from 0 4px 10px */
}

.btn:hover {
  background-color: #ff3c2e;
  transform: translateY(3px) scale(1.04); /* Reduced from translateY(4px) scale(1.05) */
  box-shadow: 0 5px 12px rgba(255, 78, 65, 0.4); /* Reduced from 0 6px 15px */
}

.btn::after {
  content: '';
  position: absolute;
  bottom: -32px; /* Reduced from -40px */
  left: -5%;
  background-color: var(--secondary-color);
  border-radius: 50%;
  transition: all var(--transition-speed) cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.btn:hover::after {
  bottom: -28px; /* Reduced from -35px */
}


/* Media query for larger screens */
@media (min-width: 768px) {
  .btn {
    font-size: 12px;
    width: 90px;
  }
}

/* Responsive Styles */
@media (max-width: 960px) {
  .navbar-main {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .navbar-brand{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-menu, .navbar-right {
    width: 100%;
    justify-content: center;
    margin-top: 12px;
    position: relative;
    left: 0rem;
  }

  .navbar-content {
    gap: 0rem;
  }

  .navbar-right {
    align-items: center;
    gap: 1rem;
  }

  .navbar-buttons {
    margin-bottom: 0;
  }

  .navbar-logo {
    flex-direction: column;
    align-items: center;
  }

  .hamburger-menu {
    display: block;
  }

  .navbar-content.mobile,
  .navbar-right.mobile {
    display: none;
    margin-top: 1rem;
  }

  .navbar-content.mobile.open,
  .navbar-right.mobile.open {
    display: flex;
  }

  .services-dropdown {
    position: static;
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
  }
}

@media (max-width: 614px) {
  .navbar-main {
    flex-direction: column;
    align-items: center;
  }

  .navbar-menu, .navbar-links {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .navbar-right {
    align-items: center;
  }

  .navbar-logo {
    flex-direction: column;
    align-items: center;
  }

  .navbar-menu a, .navbar-links a {
    font-size: 14px;
  }

  .navbar-links a[href="/career"]::after {
    display: none;
  }

  .navbar-links a[href="/login"],
  .navbar-links a[href="/signup"] {
    width: 100%;
    text-align: center;
  }

  .services-dropdown {
    grid-template-columns: 1fr;
  }
}

.user-initials-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
}

.user-initials-logo:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 10px rgba(255, 185, 0, 0.3);
}

@media (max-width: 614px) {
  .user-initials-logo {
    width: 36px;
    height: 36px;
    font-size: 14px;
  }
}
