@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');

.wp-container {
    background-color: #FFB901;
    padding: 2rem 2rem;
    text-align: center;
    color: #000000;
    font-family: 'Roboto Slab', serif;
}

.wp-title {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 2rem;
    position: relative;
}

.wp-title::after {
    content: '';
    display: block;
    width: 10rem;
    height: 3px;
    background-color: #ffffff;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
}

.wp-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.wp-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    min-width: 200px;
    margin-bottom: 20px;
}

.wp-step-icon {
    background-color: #ffffff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.wp-icon-image {
    width: 120px;
    height: 120px;
    object-fit: contain;
}

.wp-step-title {
    font-weight: bold;
    color: #ffffff;
    font-size: 1.2rem;
    margin-top: 1rem;
}

.wp-tagline {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #000000;
    font-weight: 700;
}

.wp-description {
    font-size: 1rem;
    max-width: 800px;
    margin: 0 auto;
    color: #000000;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .wp-title {
        font-size: 2rem;
    }

    .wp-steps {
        flex-direction: column;
        align-items: center;
    }

    .wp-step {
        width: 100%;
        max-width: 300px;
        margin-bottom: 30px;
    }

    .wp-tagline {
        font-size: 1.3rem;
    }

    .wp-description {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .wp-container {
        padding: 20px 10px;
    }

    .wp-title {
        font-size: 1.8rem;
    }

    .wp-step-icon {
        width: 60px;
        height: 60px;
    }

    .wp-icon-image {
        width: 40px;
        height: 40px;
    }

    .wp-step-title {
        font-size: 1rem;
    }

    .wp-tagline {
        font-size: 1.2rem;
    }

    .wp-description {
        font-size: 0.8rem;
    }
}