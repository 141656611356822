/* Footer.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.footer {
  background-color: #292929;
  color: #fff;
  padding: 40px 20px 20px;
  font-family: 'Roboto', Arial, sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 250px;
}

.footer-logo-section {
  flex: 1 1 300px;
  margin-bottom: 20px;
}

.footer-logo {
  width: 150px;
  margin-bottom: 10px;
}

.footer-tagline {
  color: #3498db;
  margin-bottom: 10px;
  font-weight: 500;
}

.footer-description {
  font-size: 14px;
  color: #999;
  line-height: 1.5;
  margin-bottom: 20px;
  font-weight: 300;
  max-width: 240px;
}

.footer-social h3,
.footer-links h3,
.footer-contact h3 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}

.social-icons {
  display: flex;
  flex-wrap: wrap;
}

.social-icon {
  width: 30px;
  height: 30px;
  background-color: #444;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.social-icon:hover {
  opacity: 0.8;
}

.facebook { background-color: #3b5998; }
.twitter { background-color: #1da1f2; }
.instagram { background-color: #e1306c; }
.youtube { background-color: #ff0000; }

.footer-links, .footer-contact {
  flex: 1 1 200px;
  margin-bottom: 20px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links Li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #999;
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: 300;
  padding: 10px;
  display: inline-block;
}

.footer-links a:hover {
  color: #fff;
}

.footer-links a.active {
  color: #ffd700;
  font-weight: 400;
}

.footer-contact p, .footer-contact address {
  color: #999;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
  font-weight: 300;
  padding: 10px;
}

.footer-contact svg {
  margin-right: 10px;
  color: #ffd700;
}

/* Footer options */
.footer-options {
  flex: 1 1 200px;
  margin-bottom: 20px;
}

.footer-options h3 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}

.option-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.option-item svg {
  color: #ffd700;
  margin-right: 10px;
  flex-shrink: 0;
}

.option-item select {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', Arial, sans-serif;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  max-width: 200px;
}

.option-item select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.5);
}




.option-item2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  margin-left: 5px;
}

.option-item2 svg {
  color: #ffd700;
  margin-right: 10px;
  flex-shrink: 0;
}

.option-item2 select {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', Arial, sans-serif;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  max-width: 200px;
}

.option-item2 select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.5);
}

/* Footer bottom */
.footer-bottom {
  text-align: center;
  padding-top: 20px;
  margin-top: 40px;
  border-top: 1px solid #444;
  font-size: 12px;
  color: #777;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .footer-content {
    justify-content: space-around;
  }
  
  .footer-logo-section {
    flex-basis: 100%;
    margin-bottom: 30px;
  }
  
  .footer-links, .footer-contact {
    flex-basis: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  
  .footer-logo-section, .footer-links, .footer-contact {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  
  .footer-description {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 30px 15px 15px;
  }
  
  .footer-logo {
    width: 120px;
  }
  
  .footer-social h3,
  .footer-links h3,
  .footer-contact h3 {
    font-size: 16px;
  }
  
  .footer-description,
  .footer-links a,
  .footer-contact p,
  .footer-contact address {
    font-size: 13px;
  }
  
  .footer-links li {
    margin-bottom: 5px;
  }
  
  .footer-links a {
    padding: 5px;
  }
  
  .footer-contact p, .footer-contact address {
    padding: 5px;
  }
}