.ticker-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f3e9e9;
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .ticker-item {
    text-align: center;
    padding: 10px;
    flex: 1;
    min-width: 200px;
  }
  
  .ticker-icon {
    font-size: 24px;
    color: #8b4b4b;
    margin-bottom: 10px;
  }
  
  .ticker-title {
    font-size: 16px;
    color: #4a4a4a;
    margin-bottom: 5px;
  }
  
  .ticker-value {
    font-size: 28px;
    color: #8b4b4b;
    font-weight: bold;
    margin: 5px 0;
  }
  
  .ticker-subtitle {
    font-size: 14px;
    color: #6c6c6c;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .ticker-container {
      flex-direction: column;
    }
  
    .ticker-item {
      width: 100%;
      margin-bottom: 20px;
    }
  }