/* BeforeAfterSlider.css */
.ba-slider__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.ba-slider__item {
  width: 100%;
}

.ba-slider__container {
  position: relative;
  width: 100%;
  padding-top: 75%; /* This creates a 4:3 aspect ratio */
  overflow: hidden;
  user-select: none;
}

.ba-slider__before-image,
.ba-slider__after-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.ba-slider__slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #fff;
  cursor: ew-resize;
  transition: left 0s ease-out;
}

.ba-slider__button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  cursor: ew-resize;
  transition: transform 0s ease-out;
}

.ba-slider__container:active .ba-slider__button {
  transform: translate(-50%, -50%) scale(1.1);
}

.ba-slider__button::before,
.ba-slider__button::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.ba-slider__button::before {
  left: 50%;
  border-right: 6px solid #333;
  transform: translate(-150%, -50%);
}

.ba-slider__button::after {
  right: 50%;
  border-left: 6px solid #333;
  transform: translate(150%, -50%);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ba-slider__button {
    width: 24px;
    height: 24px;
  }

  .ba-slider__button::before,
  .ba-slider__button::after {
    border-top-width: 5px;
    border-bottom-width: 5px;
  }

  .ba-slider__button::before {
    border-right-width: 5px;
  }

  .ba-slider__button::after {
    border-left-width: 5px;
  }
}