/* ServicesBanner.css */
.sb_services-banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333333;
    color: white;
    font-family: 'Roboto', sans-serif;
}

.sb_services-image-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 31.26%; /* 594 / 1900 * 100 */
    position: relative;
    overflow: hidden;
}

.sb_services-banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sb_nav-btn {
    position: absolute;
    top: 50%;
    /* transform: translateY(-50%); */
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 24px;
}

.sb_prev {
    left: 10px;
}

.sb_next {
    right: 10px;
}

.sb_pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
}

.sb_dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.sb_dot.sb_active {
    background-color: white;
}

.sb_services-content-wrapper {
    padding: 5% 3%;
    text-align: center;
    max-width: 800px;
}

.sb_services-title {
    font-size: clamp(24px, 4vw, 36px);
    margin-bottom: 3%;
    line-height: 1.2;
}

.sb_services-description {
    font-size: clamp(14px, 2vw, 16px);
    margin-bottom: 5%;
    line-height: 1.5;
}

.sb_button-group-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.sb_btn {
    padding: 10px 20px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: clamp(14px, 1.5vw, 15px);
    transition: background-color 0.3s, transform 0.2s;
    height: clamp(50px, 8vw, 65px);
    width: clamp(150px, 20vw, 200px);
    font-family: 'Roboto', sans-serif;
}

.sb_btn-quote, .sb_btn-trial {
    background-color: #cccccc;
    color: #000000;
    font-weight: 700;
}

.sb_btn:hover {
    background-color: #bbbbbb;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .sb_services-content-wrapper {
        padding: 8% 5%;
    }
}

@media (max-width: 480px) {
    .sb_services-content-wrapper {
        padding: 10% 5%;
    }

    .sb_button-group-container {
        flex-direction: column;
        align-items: center;
    }

    .sb_btn {
        width: 80%;
    }
}