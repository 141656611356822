/* ServicesTable.css */

.st-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.st-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.st-table th,
.st-table td {
  border: 1px solid #ddd;
  padding: 8px;
  margin-top: 100px;
  margin-bottom: 95px;
  align-items: center;
}

.st-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.st-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.st-subservices-btn {
  margin-bottom: 0px;
}

.st-table tr:hover {
  background-color: #f5f5f5;
}

.st-description-cell {
  max-width: 300px;
  padding: 10px;
}

.st-description-cell p {
  margin: 0;
  line-height: 1.4;
}

.st-read-more-btn {
  background: none;
  border: none;
  color: #007bff;
  padding: 0;
  font: inherit;
  cursor: pointer;
  margin-left: 5px;
}

.st-read-more-btn:hover {
  text-decoration: underline;
}

.st-image {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.st-action-buttons {
  display: flex;
  justify-content: space-around;
}

.st-icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.st-edit-btn {
  color: #4CAF50;
}

.st-delete-btn {
  color: #f44336;
}

.st-subservices-btn {
  color: #2196F3;
}

.st-add-btn {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.st-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.st-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.st-modal-content h2 {
  margin-top: 0;
}

.st-modal-content form {
  display: flex;
  flex-direction: column;
}

.st-modal-content input,
.st-modal-content textarea {
  margin-bottom: 10px;
  padding: 5px;
}

.st-submit-btn,
.st-cancel-btn,
.st-close-btn {
  margin-top: 10px;
  padding: 10px;
  color: white;
  border: none;
  cursor: pointer;
}

.st-submit-btn {
  background-color: #4CAF50;
}

.st-cancel-btn,
.st-close-btn {
  background-color: #f44336;
}

.st-loading,
.st-error {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.st-error {
  color: #f44336;
}