.jes_container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .jes_title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .jes_slider-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden;
    background-color: #777;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  
  .jes_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .jes_before-image {
    z-index: 1;
  }
  
  .jes_after-image {
    z-index: 2;
  }
  
  .jes_label {
    position: absolute;
    top: 20px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
    z-index: 3;
    transition: opacity 0.3s ease;
  }
  
  .jes_label-before { left: 20px; }
  .jes_label-after { right: 20px; }
  
  .jes_hidden {
    opacity: 0;
  }
  
  .jes_slider {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background: white;
    cursor: ew-resize;
    z-index: 4;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  
  .jes_slider-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .jes_arrow {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
  
  .jes_arrow-left {
    border-right: 8px solid #333;
    margin-right: 2px;
  }
  
  .jes_arrow-right {
    border-left: 8px solid #333;
    margin-left: 2px;
  }
  
  .jes_thumbnails {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .jes_thumbnail {
    width: 80px;
    height: 60px;
    margin: 0 10px;
    object-fit: cover;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s;
  }
  
  .jes_thumbnail.jes_active,
  .jes_thumbnail:hover {
    opacity: 1;
  }
  


  .jes_navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .jes_nav-button {
    background: #333;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 10px 15px;
    margin: 0 10px;
    border-radius: 5px;
    transition: background 0.3s;
  }
  
  .jes_nav-button:hover {
    background: #555;
  }
  
  @media (max-width: 768px) {
    .jes_container {
      padding: 10px;
    }
  
    .jes_title {
      font-size: 20px;
    }
  
    .jes_label {
      font-size: 14px;
    }
  
    .jes_thumbnail {
      width: 60px;
      height: 45px;
      margin: 0 5px;
    }
  
    .jes_nav-button {
      font-size: 20px;
      padding: 8px 12px;
    }
  
    .jes_slider-circle {
      width: 32px;
      height: 32px;
    }
  
    .jes_arrow {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }
  
    .jes_arrow-left {
      border-right: 7px solid #333;
    }
  
    .jes_arrow-right {
      border-left: 7px solid #333;
    }
  }
  
  @media (max-width: 480px) {
    .jes_thumbnail {
      width: 40px;
      height: 30px;
    }
  
    .jes_label {
      font-size: 12px;
    }
  
    .jes_nav-button {
      font-size: 16px;
      padding: 6px 10px;
    }
  
    .jes_slider-circle {
      width: 28px;
      height: 28px;
    }
  
    .jes_arrow {
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  
    .jes_arrow-left {
      border-right: 6px solid #333;
    }
  
    .jes_arrow-right {
      border-left: 6px solid #333;
    }
  }