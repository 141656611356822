.uat-user-admin-table-container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.uat-user-admin-table-container h2 {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
}

.uat-user-admin-table-container h3 {
  color: #34495e;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 20px;
}

.uat-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.uat-button-container button {
  padding: 12px 24px;
  margin: 5px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.uat-button-container button:hover {
  background-color: #2980b9;
}

.uat-data-table-wrapper {
  overflow-x: auto;
}

.uat-data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.uat-data-table th,
.uat-data-table td {
  padding: 15px;
  text-align: left;
  white-space: nowrap;
}

.uat-data-table th {
  background-color: #3498db;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.uat-data-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.uat-data-table tr:hover {
  background-color: #e8f4f8;
}

.uat-loading,
.uat-error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #7f8c8d;
}

.uat-error {
  color: #e74c3c;
}

.uat-action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.uat-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.uat-icon-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.uat-view-button svg {
  fill: #4CAF50;
}

.uat-delete-button svg {
  fill: #F44336;
}

.uat-icon-button svg {
  width: 24px;
  height: 24px;
}

.uat-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.uat-modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.uat-modal-content h2 {
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.uat-modal-content button {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.uat-modal-content button:hover {
  background-color: #45a049;
}

.uat-jobs-list {
  margin-top: 20px;
}

.uat-job-item {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.uat-job-item h3 {
  margin-top: 0;
  color: #333;
}

.uat-job-item p {
  margin: 5px 0;
  color: #666;
}

.uat-job-item h4 {
  margin: 10px 0 5px;
  color: #444;
}

.uat-job-item ul {
  margin: 0;
  padding-left: 20px;
  color: #555;
}

@media (max-width: 768px) {
  .uat-user-admin-table-container {
    padding: 10px;
  }

  .uat-user-admin-table-container h2 {
    font-size: 20px;
  }

  .uat-user-admin-table-container h3 {
    font-size: 18px;
  }

  .uat-data-table {
    font-size: 14px;
  }

  .uat-data-table th,
  .uat-data-table td {
    padding: 10px;
  }

  .uat-button-container button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .uat-data-table {
    font-size: 12px;
  }

  .uat-data-table th,
  .uat-data-table td {
    padding: 8px;
  }

  .uat-button-container button {
    padding: 8px 16px;
    font-size: 12px;
  }
}
.uat-delete-job-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
}

.uat-delete-job-button:hover {
  background-color: #ff3333;
}