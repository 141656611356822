/* ServiceFeatures.css */
.service-features {
  background-color: #0a4c7a;
  color: white;
  padding: 2rem 2rem;
  text-align: center;
  font-family: 'Roboto Slab', serif;
}

.service-features h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 300;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.service-features h2::after {
  content: '';
  display: block;
  width: 10rem;
  height: 3px;
  background-color: #ffa500;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.service-features p {
  max-width: 1000px;
  margin: 0 auto 2rem;
  line-height: 1.6;
  font-weight: lighter;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.1);
}

.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #0a4c7a;
}

.feature-icon {
  width: 150px;
  height: 150px;
  margin-bottom: 1rem;
  object-fit: contain;
}

.feature-item:hover .feature-icon {
  filter: drop-shadow(0 0 3px #ffd700);
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .features-grid {
    grid-template-columns: 1fr;
  }
}