.service-list {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .service-list-title {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .service-item {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .service-name {
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .service-description {
    color: #34495e;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .service-images {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .image-container {
    text-align: center;
    width: 48%;
  }
  
  .service-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .image-label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
    color: #7f8c8d;
  }
  
  .sub-services-title {
    color: #2c3e50;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .sub-services-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .sub-service-item {
    background-color: #ecf0f1;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    color: #34495e;
  }
  
  .loading, .error {
    text-align: center;
    font-size: 18px;
    margin-top: 50px;
    color: #34495e;
  }
  
  .error {
    color: #e74c3c;
  }