
/* ImageGallery.css */

.ig-container {
  width: 100%;
  padding: 1px 1px 40px 1px;
  margin-top: 1px;
  font-family: 'Roboto Slab', serif;
  overflow: hidden;
}

.ig-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1px;
  margin-bottom: 30px;
  transition: transform 0.5s ease-in-out;
}

.ig-gallery-item {
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.ig-gallery-item:hover {
  transform: scale(1.05);
}

.ig-gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.ig-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 215, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ig-gallery-item:hover .ig-overlay {
  opacity: 1;
}

.ig-search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  color: #333;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.ig-search-icon:hover {
  background-color: #fff;
  transform: scale(1.1);
}

.ig-full-portfolio-btn {
  display: block;
  width: 201px;
  margin: 0 auto;
  padding: 12px 24px;
  background-color: #ffd700;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.ig-full-portfolio-btn:hover {
  background-color: #ffea00;
  transform: translateY(-2px);
}

/* Image Lightbox */
.ig-lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ig-lightbox-content {
  position: relative;
  width: 80%;
  height: 80%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ig-lightbox-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.ig-lightbox-close,
.ig-lightbox-prev,
.ig-lightbox-next {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s;
}

.ig-lightbox-close:hover,
.ig-lightbox-prev:hover,
.ig-lightbox-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.ig-lightbox-close {
  top: 10px;
  right: 10px;
}

.ig-lightbox-prev {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.ig-lightbox-next {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* Landing Banner */
.ig-banner-container {
  position: relative;
  width: 100%;
  margin: 20px 0;
  overflow: hidden;
}

.ig-banner {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: grab;
  user-select: none;
}

.ig-banner:active {
  cursor: grabbing;
}

.ig-banner-image {
  width: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  pointer-events: none;
  transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.ig-banner-nav {
  position: absolute;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 24px;
  color: #333;
}

.ig-banner-nav:hover {
  background-color: rgba(255, 255, 255, 0.8);
  transform: scale(1.1);
}

.ig-banner-nav.ig-prev {
  left: 10px;
}

.ig-banner-nav.ig-next {
  right: 10px;
}

.ig-banner-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.ig-banner-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.ig-banner-dot:hover {
  transform: scale(1.2);
}

.ig-banner-dot.ig-active {
  background-color: #FFC107;
}

@keyframes slowDrag {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(var(--drag-x));
  }
}

.ig-banner.dragging {
  transition: none;
  animation: slowDrag 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

@media screen and (max-width: 768px) {
  .ig-gallery {
    grid-template-columns: repeat(3, 1fr);
  }

  .ig-search-icon {
    width: 40px;
    height: 40px;
  }

  .ig-banner-nav {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  .ig-banner-dots {
    bottom: 15px;
  }

  .ig-banner-dot {
    width: 8px;
    height: 8px;
  }
}

@media screen and (max-width: 480px) {
  .ig-gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .ig-search-icon {
    width: 36px;
    height: 36px;
  }

  .ig-banner-nav {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }

  .ig-banner-dots {
    bottom: 10px;
  }

  .ig-banner-dot {
    width: 6px;
    height: 6px;
  }
}

