.exp-banner__container {
    width: 100%;
    overflow: hidden;
  }
  
  .exp-banner__image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .exp-banner__image {
      height: 400px;
      object-position: center;
    }
  }
  
  @media (max-width: 480px) {
    .exp-banner__image {
      height: 300px;
    }
  }