@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.free-trial-banner {
  background-color: #0a4c7f;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  flex-wrap: wrap;
}

.banner-image {
  height: 145px;
  width: auto;
  object-fit: contain;
  max-width: 200px;
  opacity: 0.2;
}

.banner-image.left {
  margin-right: 20px;
}

.banner-image.right {
  margin-left: 20px;
}

.banner-content {
  flex: 1;
  text-align: center;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-title {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 500;
  font-style: italic;
}

.highlight-domain {
  color: #ffd700;
}

.highlight-free {
  color: #32cd32;
}

.banner-description {
  font-size: 14px;
  margin: 0 0 5px 0;
  font-weight: 300;
}

.second {
  font-weight: 300;
}

.get-quote-button {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  padding: 8px 16px;
  font-size: 18px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin-top: 15px;
}

.get-quote-button:hover {
  background-color: white;
  color: #0a4c7f;
}

@media (max-width: 1024px) {
  .banner-title {
    font-size: 22px;
  }

  .banner-description {
    font-size: 13px;
  }

  .get-quote-button {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .free-trial-banner {
    flex-direction: column;
    padding: 15px;
  }

  .banner-image {
    height: 100px;
    max-width: 150px;
    margin: 10px 0;
  }

  .banner-image.left,
  .banner-image.right {
    margin: 10px 0;
  }

  .banner-content {
    margin: 10px 0;
  }

  .banner-title {
    font-size: 20px;
  }

  .banner-description {
    font-size: 12px;
  }

  .get-quote-button {
    font-size: 14px;
    padding: 6px 12px;
  }
}

@media (max-width: 480px) {
  .free-trial-banner {
    padding: 10px;
  }

  .banner-image {
    height: 80px;
    max-width: 120px;
  }

  .banner-title {
    font-size: 18px;
  }

  .banner-description {
    font-size: 11px;
  }

  .get-quote-button {
    font-size: 12px;
    padding: 5px 10px;
  }
}