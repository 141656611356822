@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(-45deg, #f39c12, #e67e22, #d35400, #c0392b);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  font-family: 'Poppins', sans-serif;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.login-card {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  backdrop-filter: blur(10px);
  animation: cardFloat 6s ease-in-out infinite;
}

@keyframes cardFloat {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.login-title {
  color: #333;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: center;
}

.login-subtitle {
  color: #666;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 2rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  position: relative;
}

.form-group label {
  position: absolute;
  top: -0.5rem;
  left: 0.75rem;
  background-color: white;
  padding: 0 0.25rem;
  font-size: 0.875rem;
  color: #666;
  transition: all 0.3s ease;
}

.form-group input {
  width: 100%;
  padding: 1.25rem;
  padding-top: 1.24rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus {
  border-color: #667eea;
  box-shadow: 0 0 0 2px rgba(102, 126, 234, 0.2);
}

.form-group input:focus + label {
  color: #667eea;
  top: -0.75rem;
  font-size: 0.75rem;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #667eea;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover {
  background-color: #5a6fe0;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.error-message {
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: -0.5rem;
}

.login-footer {
  margin-top: 1.5rem;
  text-align: center;
}

.forgot-password {
  color: #667eea;
  text-decoration: none;
  font-size: 0.875rem;
}

.signup-prompt {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #666;
}

.signup-prompt a {
  color: #667eea;
  text-decoration: none;
  font-weight: 500;
}

@media (max-width: 480px) {
  .login-card {
    padding: 2rem;
  }
}