.js-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .js-services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .js-service-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
  }
  
  .js-service-dot {
    width: 8px;
    height: 8px;
    background-color: #999;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .js-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  
  .js-button {
    padding: 10px 20px;
    border: 1px solid #000000;
    border-radius: 25px;
    background-color: white;
    color: #000000;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .js-button:hover {
    background-color: #333;
    color: white;
  }
  
  .js-view-all {
    color: #000000;
    text-decoration: none;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 10px;
    font-weight: bold;
  }
  
  .js-view-all:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 600px) {
    .js-services-grid {
      grid-template-columns: 1fr;
    }
  
    .js-buttons-container {
      flex-direction: column;
      align-items: stretch;
    }
  
    .js-button {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .js-view-all {
      align-self: center;
      margin-left: 0;
    }
  }