/* AboutUs.css */
.about-us {
    font-family: 'Roboto Slab', serif;
    padding: 2rem 2rem;
    background-color: #f0f8ff;
    position: relative;
    overflow: hidden;
}

.background-circles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.circle {
    position: absolute;
    border-radius: 50%;
    opacity: 0.6;
}

.circle-1 {
    width: 300px;
    height: 300px;
    background-color: #FFD700;
    top: -100px;
    left: -100px;
}

.circle-2 {
    width: 200px;
    height: 200px;
    background-color: #FFA500;
    bottom: -50px;
    right: -50px;
}

.circle-3 {
    width: 150px;
    height: 150px;
    background-color: #FF6347;
    top: 20%;
    right: 10%;
}

.section-title {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    font-weight: 300;
    margin-bottom: 3rem;
    position: relative;
    z-index: 1;
}

.section-title::after {
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background-color: #ffa500;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.about-content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;
    position: relative;
    z-index: 1;
}

.about-content {
    flex: 1;
    max-width: 50%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.about-content h3 {
    font-size: 2rem;
    color: #ffa500;
    margin-bottom: 1.5rem;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

.about-content p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #333;
}

.about-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.illustration {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.illustration img {
    width: 100%;
    height: auto;
    display: block;
}

/* Responsive design */
@media (max-width: 992px) {
    .about-content-wrapper {
        flex-direction: column;
        text-align: center;
    }
  
    .about-content, .about-image {
        max-width: 100%;
    }
  
    .about-content {
        margin-bottom: 3rem;
    }

    .illustration {
        max-width: 80%;
    }

    .circle-3 {
        top: 50%;
        right: -50px;
    }
}

@media (max-width: 768px) {
    .about-us {
        padding: 3rem 1rem;
    }

    .section-title {
        font-size: 2rem;
    }

    .about-content h3 {
        font-size: 1.8rem;
    }

    .about-content p {
        font-size: 1rem;
    }
}