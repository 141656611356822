/* PaymentPage.css */

/* Main Container styles */
.payment-page {
    max-width: 1200px;
    margin: 40px auto;
    padding: 40px;
    font-family: 'Roboto Slab', serif;
    background-color: #ffffff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

/* Header styles */
.payment-page h2 {
    text-align: center;
    color: #ffffff;
    margin-bottom: 50px;
    font-size: 3em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    padding: 20px 30px;
    overflow: hidden;
    background: linear-gradient(135deg, #00c271, #2e4e7d);
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(46, 57, 125, 0.3);
    transition: all 0.3s ease;
}

.payment-page h2:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(46, 57, 125, 0.4);
}

/* Job details styles */
.job-details {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.job-details p {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
}

/* Form styles */
form {
    display: grid;
    /* gap: 20px; */
}

form > div {
    display: flex;
    flex-direction: column;
}

label {
    font-weight: 500;
    margin-bottom: 5px;
    color: #333;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
select {
    width: 100%;
    padding: 12px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1em;
    transition: all 0.3s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus,
select:focus {
    outline: none;
    /* box-shadow: 0 0 0 3px rgba(0, 194, 113, 0.2); */
}

textarea {
    resize: vertical;
    min-height: 100px;
}

/* Payment method styles */
#paymentMethod {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    padding-right: 40px;
}

/* PayPal and Razorpay button styles */
.paypal-buttons,
button {
    margin-top: 20px;
}

button {
    background-color: #00c271;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1em;
    font-weight: 500;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

button:hover {
    background-color: #00a861;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Invoice modal styles */
.invoice-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.invoice-modal-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    width: 80%;
    max-width: 800px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.invoice-modal-content h2 {
    color: #333;
    margin-bottom: 20px;
}

.invoice-modal-content button {
    margin-right: 10px;
    margin-top: 20px;
}

.buttons-container {
    transition: all 0.3s ease-in-out;
    display: flex;
    height: 100%;
    justify-content: center;
}

/* Responsive design */
@media (max-width: 768px) {
    .payment-page {
        padding: 20px;
    }

    .payment-page h2 {
        font-size: 2em;
        padding: 15px 20px;
    }

    .invoice-modal-content {
        width: 95%;
        padding: 20px;
    }
}