/* OurServices.css */

/* Main container styles */
.our-services {
  padding: 2rem 2rem;
  text-align: center;
  font-family: 'Roboto Slab', serif;
}

.our-services h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 300;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.our-services h2::after {
  content: '';
  display: block;
  width: 10rem;
  height: 3px;
  background-color: #ffa500;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.services-description {
  max-width: 800px;
  margin: 0 auto 2rem;
  color: #666;
}

/* Service row styles */
.service-rows {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.service-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

/* Service item styles */
.service-item {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.service-image {
  margin-bottom: 1rem;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.service-item p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
  flex-grow: 1;
}

/* Service links styles */
.service-links {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.service-links a {
  text-decoration: none;
  color: var(--text-color);
  transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.service-links a:hover {
  color: var(--primary-color);
  transform: translateY(-2px);
}

/* Trial and Quote button styles */
.service-links a[href="#trial"],
.service-links .quote-link {
  border: 2px solid var(--primary-color);
  border-radius: 25px;
  padding: 8px 20px;
  font-weight: 500;
  transition: all var(--transition-speed) cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.service-links a[href="#trial"]:hover,
.service-links .quote-link:hover {
  background-color: var(--primary-color);
  color: var(--bg-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 185, 0, 0.3);
}

/* Additional style for Quote button */
.service-links .quote-link {
  background-color: var(--primary-color);
  color: var(--bg-color);
}

.service-links .quote-link:hover {
  background-color: #e6a800;
  border-color: #e6a800;
}


/* View More Services button styles */
.view-more-container {
  text-align: center;
}

.view-more-btn {
  background-color: var(--primary-color);
  color: var(--bg-color);
  border: none;
  border-radius: 25px;
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all var(--transition-speed) cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.view-more-btn:hover {
  background-color: #e6a800;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 185, 0, 0.3);
}

/* Responsive design */
@media (max-width: 1024px) {
  .service-row {
    flex-direction: column;
  }

  .service-image {
    height: 250px;
  }
}

@media (max-width: 600px) {
  .service-image {
    height: 200px;
  }
}