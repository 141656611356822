/* SpecificServices.css */
.ss5x2a {
    font-family: 'Roboto' , sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .ss5x2b {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .ss5x2c {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .ss5x2d {
    flex: 1;
    min-width: 300px;
  }
  
  .ss5x2e {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  .ss5x2f {
    list-style-type: none;
    padding: 0;
  }
  
  .ss5x2f li {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
  }
  
  .ss5x2f li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #4a90e2;
  }
  
  .ss5x2g {
    display: inline-block;
    color: #000;
    text-decoration: underline;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .ss5x2h {
    flex: 1;
    min-width: 300px;
  }
  
  .ss5x2i {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    cursor: ew-resize;
  }
  
  .ss5x2j {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .ss5x2l {
    clip-path: inset(0 50% 0 0);
  }
  
  .ss5x2m {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background: white;
    left: 50%;
    transform: translateX(-50%);
    cursor: ew-resize;
    z-index: 10;
  }
  
  .ss5x2n {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .ss5x2o {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
  
  .ss5x2p {
    border-right: 6px solid #333;
    margin-right: 2px;
  }
  
  .ss5x2q {
    border-left: 6px solid #333;
    margin-left: 2px;
  }
  
  .ss5x2r {
    position: absolute;
    top: 20px;
    padding: 5px 10px;
    background: rgba(71, 71, 71, 0.5);
    color: white;
    font-size: 14px;
    transition: opacity 0.3s ease;
  }
  
  .ss5x2s {
    left: 20px;
  }
  
  .ss5x2u {
    right: 20px;
  }
  
  .ss5x2t {
    opacity: 0;
  }
  
  .ss5x2v {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 0px;
  }
  
  .ss5x2w {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #4a90e2;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .ss5x2w:hover {
    background-color: #3a78c2;
  }
  
  @media (max-width: 768px) {
    .ss5x2c {
      flex-direction: column;
    }
    
    .ss5x2i {
      height: 300px;
    }
  }