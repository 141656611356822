/* ClientJobDashboard.css */

:root {
    --cjd-primary-color: #3498db;
    --cjd-secondary-color: #2c3e50;
    --cjd-background-color: #f5f7fa;
    --cjd-card-background: #ffffff;
    --cjd-text-color: #333333;
    --cjd-border-color: #e0e0e0;
    --cjd-success-color: #2ecc71;
    --cjd-warning-color: #f39c12;
    --cjd-danger-color: #e74c3c;
}

.cjd-client-job-dashboard {
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    background-color: var(--cjd-background-color);
    color: var(--cjd-text-color);
    padding: 3rem 0;
    min-height: calc(100vh - 60px);
}

.cjd-client-job-dashboard h1 {
    color: var(--cjd-secondary-color);
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 2rem;
    text-align: center;
    letter-spacing: 1px;
}

.cjd-job-sections {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.cjd-job-sections section {
    flex: 1;
    min-width: 300px;
    max-width: 400px;
}

.cjd-job-sections h2 {
    color: var(--cjd-secondary-color);
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--cjd-primary-color);
}

.cjd-job-card {
    background-color: var(--cjd-card-background);
    border: 1px solid var(--cjd-border-color);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    margin-bottom: 1.5rem;
}

.cjd-job-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.cjd-card-body {
    padding: 1.5rem;
}

.cjd-card-title {
    color: var(--cjd-primary-color);
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.cjd-card-text {
    color: var(--cjd-text-color);
    font-size: 0.95rem;
    margin-bottom: 0.75rem;
    display: flex;
    justify-content: space-between;
}

.cjd-btn {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: all 0.3s ease;
    text-decoration: none;
    display: inline-block;
}

.cjd-btn-primary {
    background-color: var(--cjd-primary-color);
    border-color: var(--cjd-primary-color);
    color: white;
}

.cjd-btn-primary:hover {
    background-color: #2980b9;
    border-color: #2980b9;
}

.cjd-btn-secondary {
    background-color: var(--cjd-secondary-color);
    border-color: var(--cjd-secondary-color);
    color: white;
}

.cjd-btn-secondary:hover {
    background-color: #34495e;
    border-color: #34495e;
}

.cjd-text-success { color: var(--cjd-success-color); }
.cjd-text-warning { color: var(--cjd-warning-color); }
.cjd-text-danger { color: var(--cjd-danger-color); }

.cjd-progress-bar {
    height: 6px;
    background-color: #e0e0e0;
    border-radius: 3px;
    overflow: hidden;
    margin-top: 0.5rem;
}

.cjd-progress-bar-fill {
    height: 100%;
    background-color: var(--cjd-primary-color);
    transition: width 0.3s ease;
}

@media (max-width: 1200px) {
    .cjd-job-sections section {
        flex-basis: calc(50% - 1rem);
    }
}

@media (max-width: 768px) {
    .cjd-job-sections section {
        flex-basis: 100%;
    }
    
    .cjd-client-job-dashboard h1 {
        font-size: 2rem;
    }
}