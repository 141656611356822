/* Services-Services.css */
.ss_services-container {
    max-width: 1000px;
    width: 90%;
    margin: 0 auto;
    padding: 5% 2%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.ss_services-title {
    font-size: clamp(28px, 5vw, 40px);
    color: #000000;
    margin-bottom: 0.5em;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    line-height: 1.2;
}

.ss_services-underline {
    width: clamp(100px, 12.5%, 125px);
    height: 3px;
    background-color: #e6a800;
    border-color: #e6a800;
    margin: 0 auto 1.5em;
}

.ss_services-description {
    font-size: clamp(14px, 2vw, 16px);
    line-height: 1.6;
    color: #000000;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    padding-top: 1em;
    max-width: 90ch;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .ss_services-container {
        padding: 8% 4%;
    }

    .ss_services-underline {
        margin-bottom: 1em;
    }

    .ss_services-description {
        line-height: 1.5;
    }
}

@media (max-width: 480px) {
    .ss_services-container {
        padding: 10% 5%;
    }

    .ss_services-underline {
        width: 30%;
    }
}