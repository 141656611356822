@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;700&display=swap');

/* Hero container */
.hero {
    position: relative;
    height: 750px;
    overflow: hidden;
    font-family: 'Roboto Slab', serif;
}

/* Content container */
.hero-content-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 1s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 1;
}

.hero-content-container.slide-left {
    transform: translateX(-100%);
    opacity: 0;
}

/* Video container */
.hero-video-container {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    transition: transform 1s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
}

.hero-video-container.slide-left {
    transform: translateX(-100%);
    opacity: 1;
}

.hero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    background-size: cover;
    background-position: center;
}

.hero-slide.active {
    opacity: 1;
}

/* Hero content styles */
.hero-content {
    position: absolute;
    top: 22%;
    left: 11%;
    transform: translateY(-50%);
    color: black;
    max-width: 40%;
}

.hero-content h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    font-weight: 300;
}

.hero-content p {
    margin-bottom: 20px;
    font-weight: 400;
}

/* Button styles */
.btn-try {
    padding: 12px 24px;
    background-color: #FFFFFF;
    color: #000000;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Custom Indicator styles */
.hero .hero-indicators-custom {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: 10;
}

.hero .hero-indicators-custom .indicator-custom {
    width: 6px !important;
    height: 6px !important;
    border-radius: 50% !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
    margin: 0 4px !important;
    padding: 0 !important;
    border: none !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease, transform 0.3s ease !important;
}

.hero .hero-indicators-custom .indicator-custom.active-custom {
    background-color: #FFFFFF !important;
    transform: scale(1.2) !important;
}